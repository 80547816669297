import { Component, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Store } from "@ngxs/store";
import { AuthAction } from 'src/app/shared/auth';
import { AccountStore } from "../shared/account";

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {

    constructor(public layoutService: LayoutService, private store: Store) { }

    accountStore = inject(AccountStore);

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    onSignOut() {
        this.store.dispatch(new AuthAction.Logout());
    }
}
