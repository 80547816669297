<router-outlet></router-outlet>
<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="p-toast-message-text" data-pc-section="text">
      <div class="p-toast-summary" data-pc-section="summary">{{message.summary}}</div>
      <div class="p-toast-detail" data-pc-section="detail" innerHtml="{{message.detail}}"></div>
    </div>
  </ng-template>
</p-toast>
<p-confirmDialog />
<manager-global-loading-indicator-overlay></manager-global-loading-indicator-overlay>
