<div *ngIf="service.isAnyTaskPending()" class="loading-overlay">
  <!-- *ngIf="asyncInitializationService.tasksArePending$ | async"  -->
  <div class="flex flex-column">
    <div class="flex align-items-center mb-4">
      <img alt="image" src="assets/img/cs-manager-logo.svg" style="height: 80px;">
      <!--<div class="flex flex-column ml-1 py-0 gap-0">
        <span class="font-bold mb-0 text-3xl" style="letter-spacing: 1px">STANDORTMONITOR</span>
        <span class="mt-0 text-sm">Umgebung wird geladen...</span>
      </div>-->
    </div>
    <div class="">
      <p-progressBar [style]="{ height: '4px' }" mode="indeterminate"></p-progressBar>
    </div>
  </div>
</div>
