import { Component, inject, LOCALE_ID } from '@angular/core';
import { PrimeNGConfig, Translation } from "primeng/api";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  title = 'CircleSquare Manager';

  primeNgConfig = inject(PrimeNGConfig);

  locale = inject(LOCALE_ID);

  ngOnInit() {
    this.setPrimeNgConfig();
  }

  setPrimeNgConfig() {
    const localeTranslations: Record<string, Translation> = {
      'en': {
        dateFormat: 'mm/dd/yy',
      },
      'de': {
        dateFormat: 'dd.mm.yy',
      }
    }
    console.log('setPrimeNgConfig', this.locale);
    this.primeNgConfig.setTranslation(localeTranslations[this.locale]);
  }
}
