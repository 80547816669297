import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, concat, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { environment } from "../../../environments/environment";
import { StorageState } from "../../storage/storage.state";
import { Store } from "@ngxs/store";
import { AuthState } from "src/app/shared/auth";
import { ErrorMessageService } from "../services/error-message.service";
import { GraphQLError } from "graphql/error";

export function createApollo(httpLink: HttpLink, store: Store, errorMessageService: ErrorMessageService): ApolloClientOptions<any> {

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const auth = setContext((operation, context) => {

    const token = store.selectSnapshot(AuthState.accessToken) ?? store.selectSnapshot(StorageState.accessToken);

    if ('headers' in context && 'Authorization' in context['headers']) return context;
    if (!token) return context;

    return { ...context, headers: { ...context['headers'], Authorization: `Bearer ${token}` } };
  });

  const formatErrorLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      if (response.errors) {
        const errorCode = errorMessageService.getErrorCode(response.errors[0] as GraphQLError);
        let message = errorMessageService.getErrorMessageFromCode(errorCode);

        // @ts-ignore
        response.errors[0].message = message;
      }
      return response;
    });
  });

  const link = ApolloLink.from([auth, errorLink, formatErrorLink, httpLink.create({ uri: environment.serverUrl + environment.graphQLEndpoint })]); // auth
  const cache = new InMemoryCache({});

  return {
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    }
  }
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Store, ErrorMessageService],
    },
  ],
})

export class GraphQLModule {

}
