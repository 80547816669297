<div class="layout-container" [ngClass]="containerClass" style="height: 100vh; overflow: hidden;">
  <app-sidebar></app-sidebar>
  <div class="layout-content-wrapper flex flex-column" style="height: 100vh; overflow: hidden;">
    <app-topbar></app-topbar>
    <app-breadcrumb class="content-breadcrumb"></app-breadcrumb>
    <div class="layout-content flex-grow-1 overflow-hidden">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-profilemenu></app-profilemenu>
  <!--<app-config></app-config>-->
  <div class="layout-mask"></div>
</div>
