import { computed, inject, OnInit, Signal } from '@angular/core';
import { Component } from '@angular/core';
import { Action, Subject } from "src/app/shared/graphql";
import { AccountStore } from "src/app/shared/account/data-access/state/account.store";
import { MenuItem } from "primeng/api";
import { checkAbility } from "src/app/shared/auth/auth.utils";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent {

  accountStore = inject(AccountStore);

    model: Signal<MenuItem[]> = computed(() => {
      const rules = this.accountStore.abilityRules();

      const primaryMenuItems: MenuItem[] = [];

      const tmp: MenuItem[] = [
        {
          items: primaryMenuItems,
        }
      ];

      if (checkAbility(rules, Action.Manage, Subject.Club) || (checkAbility(rules, Action.ReadOwn, Subject.Club) && checkAbility(rules, Action.UpdateOwn, Subject.Club))) {
        primaryMenuItems.push(...this._getClubMenuItems());
      }

      primaryMenuItems.push(...this._getEventMenuItems());

      if (checkAbility(rules, Action.Manage, Subject.Notification)) {
        tmp.push(...this._getNotificationMenuItems());
      }

      tmp.push({
        label: 'Admin',
        items: [
          {
            label: 'Club Settings',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/club/edit']
          }
        ]
      });

      return tmp;
    });

    _getEventMenuItems() {
      return [
        {
          label: 'Events',
          icon: 'pi pi-fw pi-list',
          routerLink: ['/events']
        },
        {
          label: 'Leagues',
          icon: 'pi pi-fw pi-lock',
          routerLink: ['/leagues'],
          badge: 'Coming Soon',
          disabled: true
        },
        {
          label: 'Tournaments',
          icon: 'pi pi-fw pi-lock',
          routerLink: ['/tournaments'],
          badge: 'Coming Soon',
          disabled: true
        }
      ];
    }

    _getClubMenuItems() {
      return [
        {
          label: 'Schedule',
          icon: 'pi pi-fw pi-calendar',
          routerLink: ['/reservations']
        },
      ];
    }

    _getNotificationMenuItems() {
      return [{
        label: 'Notifications',
        items: [
          {
            label: 'Send Notification',
            icon: 'pi pi-fw pi-envelope',
            routerLink: ['/notifications/send-notification']
          }
        ]
      }];
    }
}
