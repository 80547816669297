import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from "./layout/app.layout.module";
import { GraphQLModule } from "./shared/graphql/graphql.module";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxsModule } from "@ngxs/store";
import { StorageState } from "./storage/storage.state";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { STORAGE_STATE } from "./state";
import { RouterState } from "./state/router/router.state";
import {StoreModule} from "@ngrx/store";
import {GoogleMapsModule, MapMarker} from "@angular/google-maps";
import {AbilityModule} from "@casl/angular";
import {MongoAbility, AbilityBuilder, PureAbility, createMongoAbility} from "@casl/ability";
import {ToastrModule} from "ngx-toastr";
import {AutoCompleteModule} from "primeng/autocomplete";
import { DialogService } from "primeng/dynamicdialog";
import { DialogModule } from "primeng/dialog";
import { DialogRef } from "@angular/cdk/dialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { EffectsModule } from "@ngrx/effects";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import {
  ScheduleModule,
  DayService,
  WeekService,
  WorkWeekService,
  MonthService,
  AgendaService,
  ResizeService, DragAndDropService
} from '@syncfusion/ej2-angular-schedule';
import { CLUB_DETAILS_FACADE_TOKEN } from "./shared/interfaces/club-details-facade.interface";
import { ClubFacade } from "./domains/club/club.facade";
import { AuthState } from "./shared/auth";
import { SelectButtonModule } from "primeng/selectbutton";
import { GlobalLoadingIndicatorOverlayComponent } from './shared/global-loading-indicator/global-loading-indicator-overlay/global-loading-indicator-overlay.component';
import { ProgressBarModule } from "primeng/progressbar";
import { FullCalendarModule } from "@fullcalendar/angular";
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({ declarations: [
        AppComponent,
        GlobalLoadingIndicatorOverlayComponent,
    ],
    exports: [],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        AppLayoutModule,
        GraphQLModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        AbilityModule,
        AutoCompleteModule,
        ToastrModule.forRoot(),
        NgxsStoragePluginModule.forRoot({ keys: [STORAGE_STATE] }),
        NgxsModule.forRoot([
            RouterState,
            AuthState,
            StorageState,
        ], { developmentMode: true } // !environment.production
        ),
        NgxsLoggerPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),
        StoreModule.forRoot(),
        EffectsModule.forRoot([]),
        ToastModule,
        ConfirmDialogModule,
        ScheduleModule,
        SelectButtonModule,
        ProgressBarModule,
        FullCalendarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })], providers: [
        { provide: PureAbility, useExisting: new PureAbility() },
        { provide: CLUB_DETAILS_FACADE_TOKEN, useClass: ClubFacade },
        DialogService,
        MessageService,
        ConfirmationService,
        DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
