// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  environmentName: 'Development',

  production: false,

  graphQLEndpoint: "/graphql",
  subscriptionEndpoint: "wss://api-v2.dev.circlesquare.app/graphql",
  // subscriptionEndpoint: "wss://localhost:3000/graphql",
  serverUrl: "https://api-v2.dev.circlesquare.app",
  // serverUrl: "http://localhost:3000",
  mapsApiKey: 'AIzaSyAfWckwRowPFRgQ1608XZEgWy7oDdpI_mA',
};
