import { Injectable } from '@angular/core';
import { Store } from "@ngxs/store";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { AuthAction } from "../state/auth.actions";
import { AuthState } from "../state/auth.state";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(private store: Store, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.router.url !== '/auth/login') {
    await lastValueFrom(this.store.dispatch(new AuthAction.Initialize()));
    // }
    if (this.store.selectSnapshot<boolean>(AuthState.isLoggedIn)) {
      console.log("User is authenticated");
      return true;
    }
    console.log("User is not authenticated", state.url);
    this.store.dispatch(new AuthAction.NavigationFailed(state.url))
    await this.router.navigate(['/login']);
    return false;
  }

  async canActivateChild() {
    /*if (await this.authService.isAuthenticated()) {
      console.log("User is authenticated");
      return true;
    }
    console.log("User is not authenticated");
    this.route.navigate(['/login']);
    return false;*/

    return true;
  }
}
