/// <reference types="@angular/localize" />
import { registerLicense } from '@syncfusion/ej2-base';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GlobalAnimationMode, setGlobalAnimation } from "@syncfusion/ej2-base";

import { AppModule } from './app/app.module';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdcdXRcRWFeVUxyXkY=');
setGlobalAnimation(GlobalAnimationMode.Disable);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
