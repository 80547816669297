import { Component, inject } from '@angular/core';
import { GlobalLoadingIndicatorService } from "../global-loading-indicator.service";

@Component({
  selector: 'manager-global-loading-indicator-overlay',
  templateUrl: './global-loading-indicator-overlay.component.html',
  styleUrl: './global-loading-indicator-overlay.component.scss'
})
export class GlobalLoadingIndicatorOverlayComponent {

  service = inject(GlobalLoadingIndicatorService);

}
