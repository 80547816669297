import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuardService } from "src/app/shared/auth/guards/auth-guard.service";
const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        // component: HomePageComponent
        redirectTo: 'events',
      },
      {
        path: 'events',
        loadChildren: () => import('./features/event/event.module').then(m => m.EventModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./features/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'reservations',
        loadChildren: () => import('src/app/domains/court-reservations')
      },
      {
        path: 'club',
        loadChildren: () => import('src/app/domains/club')
      }
    ],
  },
  {
    path: '',
    loadChildren: () => import('src/app/domains/sign-in')
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
